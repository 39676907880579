/** Custom Select */

'use strict'

$(document).ready(function () {
  // Toggle open the dropdown on select clicked 
  $('.js-custom-select').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    if ($(this).parent().find('.js-custom-select-dropdown').hasClass('is-open')) {
      $('.js-custom-select-dropdown').removeClass('is-open');
      $(this).css({
        'z-index' : '10'
      });
    } else if ($(this).find('.js-custom-select-dropdown').hasClass('is-open')) {
      $('.js-custom-select-dropdown').removeClass('is-open');
      $(this).css({
        'z-index' : '10'
      });
    } else {
      $(this).find('.js-custom-select-dropdown').toggleClass('is-open');
      $(this).css({
        'z-index' : '100'
      });
    }
  });

  // Update the value on select option clicked
  $('.js-custom-select .js-custom-select-dropdown .custom-select__items').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();

    // Update active item
    $(this).parent().find('.active').removeClass('active');
    $(this).addClass('active');

    // Data of clicked item
    let selectID = $(this).closest('.js-custom-select').attr('id');
    let dataSelectedAttr = $(this).data("selected");
    let nameSelect = $(this).find('.cs-items__value').html();

    // Update the value with a new selected item
    $('#' + selectID).find('.custom-select__items.selected').attr('data-selected', dataSelectedAttr);
    $('#' + selectID).find('.custom-select__items.selected .cs-items__value').html(nameSelect);

    // Close the dropdown
    $('.custom-select-dropdown').removeClass('is-open');
    $(this).css({
      'z-index' : '10'
    });
  });

  // Trigger close the dropdown
  $(document).on('click', function (e) {
    e.stopPropagation();
    $('.custom-select-dropdown').removeClass('is-open');
    $(this).css({
      'z-index' : '10'
    });
  });
})